import { createStore } from "vuex";
import axios from 'axios';

export default createStore({
  state: {
    targetsite: "About",
  },
  getters: {
    getTargetSite: state => {
      return state.targetsite
    }
  },
  mutations: {
    setTargetSite(state, targetsitename){
      state.targetsite = targetsitename;
    }
  },
  actions: {},
  modules: {},
});
