<template>
    <div>
        <div class="section">
            <div class="w-container">
                <div class="section-title-group">
                    <h2 class="section-heading centered">Allgemeine Informationen</h2>
                </div>
                <div data-w-id="aed3bea6-6d8b-e3d4-2cc4-fc1734669458" class="white-box">
                  <div class="inline" v-if="editmode != 1">
                    <p><b>Straße und Hausnummer der Bude:</b> {{ general.address }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 1">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="firstName" data-name="newFirstName" placeholder="Straße und Hausnummer der Bude" id="firstName" v-model="newaddress"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 2">
                    <p><b>PLZ und Stadt der Bude:</b> {{ general.city }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 2">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="lastName" data-name="newLastName" placeholder="PLZ und Stadt der Bude" id="lastName" v-model="newcity"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 3">
                    <p><b>Google Maps Link zur Bude:</b> {{ general.locationLink }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 3">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="colName" data-name="newColName" placeholder="Google Maps Link" id="colName" v-model="newlocationLink"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 4">
                    <p><b>Kontaktemailaddresse:</b> {{ general.email }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 4">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="email" class="w-input" maxlength="256" name="email" data-name="newEmail" placeholder="Kontaktemailaddresse" id="newEmail" v-model="newemail"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 5">
                    <p><b>Kontakttelefonnummer:</b> {{ general.telephone }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 5">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="email" data-name="newEmail" placeholder="Kontakttelefonnummer" id="newEmail" v-model="newtelephone"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 6">
                    <p><b>Link für Download des Semesterprogramms:</b> {{ general.semesterprogrammlink }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 6">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="email" data-name="newEmail" placeholder="Downloadlink" id="newEmail" v-model="newsemesterprogrammlink"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Profile',
  data () {
    return {
      active: Boolean,
      editmode: Number,
      newaddress: String,
      newcity: String,
      newlocationLink: String,
      newemail: String,
      newtelephone: String,
      newsemesterprogrammlink: String,
      general: Object
    };
  },
  mounted () {
    window.scrollTo(0, 0);
    this.newaddress = "";
    this.newcity = "";
    this.newlocationLink = "";
    this.newemail = "";
    this.newtelephone = "";
    this.newsemesterprogrammlink = "";
    
    axios.get('/api2/general')
    .then((resp)=>{
      this.general = resp.data;
    })
  },
  methods: {
    logout: function (event) {
        axios.get('/api2/users/logout', {withCredentials: true})
        .then((resp)=>{
            location.reload();
        });
    },
    handleSubmit(e){
      e.preventDefault();
      this.editmode=0;

      if (this.newaddress != ""){
        this.general.address = this.newaddress;
      }
      if (this.newcity != ""){
        this.general.city = this.newcity;
      }
      if (this.newlocationLink != ""){
        this.general.locationLink = this.newlocationLink;
      }
      if (this.newemail != ""){
        this.general.email = this.newemail;
      }
      if (this.newtelephone != ""){
        this.general.telephone = this.newtelephone;
      }
      if (this.newsemesterprogrammlink != ""){
        this.general.semesterprogrammlink = this.newsemesterprogrammlink;
      }

      const newgeneral = this.general;
      axios.post('/api2/general', { "address": newgeneral.address, "city": newgeneral.city, "locationLink": newgeneral.locationLink, "email": newgeneral.email, "telephone": newgeneral.telephone, "semesterprogrammlink": newgeneral.semesterprogrammlink }, {withCredentials: true})
      .then((resp)=>{
          this.newaddress = "";
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section{
  background: transparent;
}

.white-box{
  background: transparent;
  border-color: black;
}

.inline{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.edit{
  margin-left: 10px;
}

.check{
  margin-left: 10px;
  margin-bottom: 10px;
}

.edit:hover{
  cursor: pointer;
}

.check:hover{
  cursor: pointer;
}

.section-heading.centered{
    padding-top:20px;
}

.hollow-button{
    color: black;
    border-color: black;
}

@media screen and (max-width: 479px) {
  .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }

  .hollow-button.changePwd{
    margin-top: 15px;
  }
}
@media screen and (max-width: 991px) {
  .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}
</style>