<template>
  <footer>
      <div class="footer">
    <div class="w-container">
      <div class="w-row">
        <div class="spc w-col w-col-4">
          <h5>Kontakt</h5>
          <div class="footer-link-wrapper w-clearfix"><img src="../assets/images/social-38.svg" width="20" alt="" class="info-icon">
            <a v-bind:href="'https://www.google.at/maps/place/Breite+G.+16,+1070+Wien/@48.2041057,16.3545772,17z/data=!3m1!4b1!4m5!3m4!1s0x476d0791bb6f2f45:0x2fb2a8b96d336bd9!8m2!3d48.2041057!4d16.3567659'" class="footer-link with-icon">KÖStV Nibelungia Wien<br>Breite Gasse 16<br>1070 Wien</a>
          </div>
          <div class="footer-link-wrapper w-clearfix"><img src="../assets/images/social-30.svg" width="20" alt="" class="info-icon">
            <a v-bind:href="'mailto:chc@nibelungia.at'" class="footer-link with-icon">chc@nibelungia.at</a>
          </div>
          <div class="footer-link-wrapper w-clearfix"><img src="../assets/images/social-40.svg" width="20" alt="" class="info-icon">
            <a v-bind:href="'tel:015223565'" class="footer-link with-icon">01 522 35 65</a>
          </div>
        </div>
        <div class="spc w-col w-col-4">
          <h5>Links</h5>
          <a href="https://oecv.at/" class="footer-link">ÖCV</a>
          <a href="https://mail.nibelungia.at/" class="footer-link">Nibelungia Webmail</a>
          <a href="https://intern.nibelungia.at/login.php" class="footer-link">Nibelungia Intern</a>
          
        </div>
        <div class="w-col w-col-4">
          <h5>sonstiges</h5>
          <router-link class="routerLink" to="/impressum"><a href="#" class="footer-link">Impressum</a></router-link>
          <!-- <h5>social</h5>
          <div class="footer-link-wrapper w-clearfix"><img src="../assets/images/social-18.svg" width="20" alt="" class="info-icon">
            <a href="#" class="footer-link with-icon">Twitter</a>
          </div>
          <div class="footer-link-wrapper w-clearfix"><img src="../assets/images/social-03.svg" width="20" alt="" class="info-icon">
            <a href="#" class="footer-link with-icon">Facebook</a>
          </div>
          <div class="footer-link-wrapper w-clearfix"><img src="../assets/images/social-07.svg" width="20" alt="" class="info-icon">
            <a href="#" class="footer-link with-icon">Instagramm</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="footer center">
    <div class="w-container">
      <div class="footer-text"> Copyright KÖStV Nibelungia Wien 2022 </div>
    </div>
  </div>
  </footer>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Footer',
  data () {
    return {
      general: Object
    };
  },
  mounted(){
    axios.get('/api2/general')
    .then((resp)=>{
      this.general = resp.data;
    })
  }
}
</script>

<style scoped>
a :hover{
  color: gold;
}
</style>