<template>
    <div>
        <div class="w-container">
            <div class="div-block-3">
                <div class="w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" class="form-3" @submit="handleSubmit">
                        <h2>Anmelden</h2>
                        <input type="email" class="w-input" maxlength="256" name="E-Mail" data-name="E-Mail" placeholder="E-Mail" id="E-Mail" required="" v-model="email">
                        <input type="password" class="w-input" maxlength="256" name="Passwort" data-name="Passwort" placeholder="Passwort" id="Passwort" required="" v-model="password">
                        <input type="submit" value="WEITER" data-wait="Please wait..." class="submit-button-2 w-button">            
                    </form>
                <div class="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
    name: 'Login',
    components: {
    },
    mounted () {
        window.scrollTo(0, 0);
    },
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods:{
        handleSubmit(e){
            e.preventDefault();
            axios.post('/api2/users/login', {email: this.email, password: this.password} , {withCredentials: true})
            .then((resp)=>{
                console.log(resp.data);
                if (resp.data){
                    location.reload();
                }
            })
            .catch(err => {
                createToast('E-Mail oder Passwort ist inkorrekt', {
                    type: 'warning',
                    position: 'top-center',
                    showIcon: true,
                    timeout: 5000
                })
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
    width: 100%;
}
.div-block-3{
    width: 400px;
    margin-left: 10px;
    margin-right: 10px;
}
.w-container{
    display: flex;
    justify-content:space-around;
}
</style>