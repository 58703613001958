<template>
  <div id="border">
    <div id="w-node-_493617cc-acf1-408f-9d85-9f775fb7d56a-c601eb62">
      <div class="cards-image-mask"><img :src="require('@/assets/images/' + event.img)" sizes="(max-width: 479px) 100vw" alt="" class="cards-image"></div>
      <div id="text">
        <h3 class="heading-8 gold" v-if='event.importance == "hochoffiziell"'>{{ event.title }}</h3>
        <h3 class="heading-8 green" v-if='event.importance == "offiziell"'>{{ event.title }}</h3>
        <h3 class="heading-8 white" v-if='event.importance == "frei"'>{{ event.title }}</h3>
        <p class="paragraph-4">{{ event.date }}</p>
      </div>
      <router-link class="link" :to="{ path: '/events/'+ event._id}"><a class="hollow-button">mehr erfahren</a></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Event',
  props: {
    event: Object
  },
  methods:{
    emit(){
      this.$emit('setInfoEvent', this.event);
    }
  },
  mounted(){
  }
}
</script>

<style scoped>
a{
  cursor: pointer;
}
h3{
    letter-spacing: 3px;
}
.heading-8.gold{
  color: gold;
}

.heading-8.green{
  color: yellowgreen;
}

.heading-8.white{
  color: white;
}

@media screen and (max-width: 900px) {
  .heading-8{
    font-size: 0.9em;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_493617cc-acf1-408f-9d85-9f775fb7d56a-c601eb62{
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    float: right;
  }
  #border{
    height: 100%;
    border-radius: 6px;
    border: solid white 1px;
  }
  #text{
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .hollow-button{
    text-align: center;
  }

  .cards-image-mask{
    width: 0px;
    min-height: 0;
    height: 0;
    padding-top: 0;
  }

  img{height: 0px;}

  #w-node-_493617cc-acf1-408f-9d85-9f775fb7d56a-c601eb62{
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .link{
    height: 100%;
    padding:0;
    min-width: 158px;
  }
}

@media screen and (max-width: 479px) {
  .hollow-button{
    min-width: none;
    font-size: 0.9em;
    padding-left: 7px;
    padding-right: 7px;
    letter-spacing: 0.1em;
    margin-right: 0px;
  }

  .heading-8{
    font-size: 0.9em;
    letter-spacing: 0.1em;
  }

  #w-node-_493617cc-acf1-408f-9d85-9f775fb7d56a-c601eb62{
    padding: 10px;
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 357px) {
.link{
    min-width: 0px;
    font-size: 0.9em;
  }
}

@media screen and (max-width: 300px) {
  .link{
    min-width: 0px;
    font-size: 0.7em;
  }
  .heading-8{
    font-size: 0.7em;
    letter-spacing: 0.1em;
  }
}
</style>