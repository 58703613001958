<template>
  <div>
    <div>
      <nav class="hero-section centered wf-section alt">
        <div class="w-container">
          <h1 class="heading-13">{{ event.title }}</h1>
        </div>
        <h2 class="heading-14">{{ event.date }}</h2>
      </nav>

      <div class="hochoffiziell" v-if='event.importance == "hochoffiziell"'><h5 class="offi">Hochoffiziell</h5></div>
      <div class="offiziell" v-if='event.importance == "offiziell"'><h5 class="offi">Offiziell</h5></div>

      <section id="feature-section" class="feature-section-2 wf-section">
        <div class="flex-container-2 w-container">
          <div class="feature-image-mask"><img :src="require('@/assets/images/' + event.img)" data-w-id="89439f3f-bbbb-3236-cadb-221a7bfbe9d3" sizes="(max-width: 479px) 200vw" alt=""></div>
          <div>
            <h5 class="heading-10 location">Ort: {{ event.location }}<br>‍<br>Uhrzeit: {{ event.time }}</h5>
            <p class="paragraph-7">{{ event.note }}</p>
          </div>
        </div>
      </section>
      
      <div class="center margintop">
        <router-link to="/events"><a class="hollow-button black mt">Zurück</a></router-link>
      </div>

      <div class="edit" v-if="isAdmin" v-on:click="showPop = true">
        <img src="@/assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="editImg">
      </div>
    </div>
    <Popup v-if="showPop">
        <h2>Veranstaltung bearbeiten</h2>
        <form @submit="handleSubmit">
          <input type="text" class="w-input" maxlength="256" name="title" data-name="title" placeholder="Veranstaltungsname" id="title" required="" v-model="event.title">
          <input type="text" class="w-input" maxlength="256" name="date" data-name="date" placeholder="Datum (DD.MM.YYYY)" pattern="(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20|21)\d\d" id="date" v-model="event.date">
          <input type="text" class="w-input" maxlength="256" name="time" data-name="time" placeholder="Uhrzeit (HH:MM)" pattern="(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]" id="time" v-model="event.time">
          <input type="text" class="w-input" maxlength="256" name="location" data-name="location" placeholder="Ort" id="location" v-model="event.location">
          <div class="segmented">
            <div class="option">
              <input type="radio" name="importance" id="hochoff" value="hochoffiziell" v-model="event.importance">
              <label class="optiontext" id="CC">Hochoffiziell</label>
            </div>
            <div class="option">
              <input type="radio" name="importance" id="off" value="offiziell" v-model="event.importance">
              <label class="optiontext" id="BC">Offiziell</label>
            </div>
            <div class="option">
              <input type="radio" name="importance" id="frei" value="frei" v-model="event.importance">
              <label class="optiontext" id="AC">frei</label>
            </div>
          </div>
          <div class="segmented2">
            <div class="option">
              <input type="radio" name="signup" id="print-size-s" value="true" v-model="event.signUp">
              <label class="optiontext" id="CC">Mit Anmeldung</label>
            </div>
            <div class="option">
              <input type="radio" name="signup" id="print-size-m" value="false" v-model="event.signUp">
              <label class="optiontext" id="BC">Ohne Anmeldung</label>
            </div>
          </div>
          <img >
          <div id="picture">
            <img src="@/assets/images/iconmonstr-picture-thin.svg" width="30" height="30" loading="lazy" alt="" class="check" @click="showImgPop = true">
            <a href="#" id="selectedpicture" @click="showImgPop = true">{{ event.img }}</a>
          </div>
          <textarea v-model="event.note" placeholder="Anmerkungen" class="w-input" id="moreInfo"></textarea>
          <div class="brow">
            <input type="submit" value="Speichern" data-wait="Please wait..." class="submit-button-2 w-button">
            <a class="hollow-button black" @click="showPop = false">Abbrechen</a>
          </div>
          <div class="center">
            <a class="button delete" @click="detleteEvent">LÖSCHEN</a>
          </div>
        </form>
      </Popup>
      <Popup v-if="showImgPop">
        <div class="image-list">
          <div class="element active" v-if='event.img == "Wappen-NbWHD.jpg"'>
              <img src="@/assets/images/Wappen-NbWHD.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/Wappen-NbWHD.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "Wappen-NbWHD.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "document.jpg"'>
              <img src="@/assets/images/document.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/document.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "document.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "kneipe.jpg"'>
              <img src="@/assets/images/kneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/kneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "kneipe.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "skiwochenende.jpg"'>
              <img src="@/assets/images/skiwochenende.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/skiwochenende.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "skiwochenende.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "krambambuli.jpg"'>
              <img src="@/assets/images/krambambuli.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/krambambuli.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "krambambuli.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "messe.jpg"'>
              <img src="@/assets/images/messe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/messe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "messe.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "budenabend.jpg"'>
              <img src="@/assets/images/budenabend.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/budenabend.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "budenabend.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "ac.jpg"'>
              <img src="@/assets/images/ac.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/ac.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "ac.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "FC.jpg"'>
              <img src="@/assets/images/FC.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/FC.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "FC.jpg"'>
          </div>

          <div class="element active" v-if='event.img == "trauerkneipe.jpg"'>
              <img src="@/assets/images/trauerkneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/trauerkneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='event.img = "trauerkneipe.jpg"'>
          </div>
        </div>
        <a class="hollow-button black" @click="showImgPop = false">Schliessen</a>
      </Popup>
  </div>
</template>

<script>
import gsap from 'gsap'
import axios from 'axios'
import Popup from '@/components/Popup.vue'

export default {
  name: 'Eventinfo',
  components: {
    Popup
  },
  data () {
    return {
      showPop: false,
      showImgPop: false,
      event: {
        title: "loading...",
        date: "loading...",
        type: "loading...",
        importance: "frei",
        signUp: false,
        location: "loading...",
        time: "loading...",
        img:"Wappen-NbWHD.jpg",
        note: "loading..."
      },
      isAdmin: false,
      id: this.$route.params.id
    };
  },
  mounted () {
    window.scrollTo(0, 0);
    axios.get('/api2/events/' + this.id)
    .then((resp)=>{
      this.event = resp.data;
    });
    axios.get('/api2/users/isAdmin', {withCredentials: true})
    .then((resp)=>{
      this.isAdmin = resp.data;
    });
  },
  setup(){
    const beforeEnter = (el) =>{
      el.style.opacity = 0;
      el.style.transform = 'translateY(100px)'
    }

    const enter = (el, done) =>{
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 1,
        onComplete: done,
        delay: el.dataset.index * 0.2
      })
    }

    return {beforeEnter, enter};
  },
  methods:{
        handleSubmit(e){
            e.preventDefault();
            axios.patch('/api2/events', this.event, {withCredentials: true})
            .then((resp)=>{
              location.reload();
            });
        },
        detleteEvent(){
          axios.delete('/api2/events/' + this.event._id)
          .then((resp)=>{
            this.$router.push({ name: 'Events' })
          });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button.delete{
  text-align: center;
  margin-top: 10px;
  background: crimson;
}

.button.delete:hover{
  background: rgb(194, 17, 53);
}

a{
  cursor: pointer;
}

.edit{
  position:fixed;
  top: 40px;
  bottom: 0;
  right: 10px;
  z-index: 99;
  background: rgb(255, 255, 255);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color:black;
  text-align: center;
  font-size: 2em;
  padding: 15px;
  margin-top: 30px;
  cursor: pointer;
  box-shadow: 4px 2px 2px rgba(0, 0, 0, 0.562);
}

.edit:hover{
  background: rgb(226, 226, 226);
  text-shadow: 1px 1px 2px black;
}

.hero-section.centered.wf-section.alt{
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(64, 64, 71, 0.44)), to(rgba(64, 64, 71, 0.44))), url('../../assets/images/northfolk-pxNcBLSPSMc-unsplash.jpg');
  background-image: linear-gradient(180deg, rgba(64, 64, 71, 0.44), rgba(64, 64, 71, 0.44)), url('../../assets/images/northfolk-pxNcBLSPSMc-unsplash.jpg');
}

.hollow-button.black.mt{
  margin-top: 15px;
}

.offi{
  margin: 0;
  color: black;
}
.hochoffiziell{
  text-align: center;
  background: gold;
  
}
.offiziell{
  text-align: center;
  background: rgb(54, 190, 0);
}

.heading-27.margintop{
  margin-top: 40px;
}

.center{
  display: flex;
  justify-content: center;
}

.image-list{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 600px;
}

.previewimg{
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.element{
  padding: 3px;
  border: solid 1px black;
  margin: 5px;
  transition: 200ms ease-in-out;
  background: gray;
}

.element.active{
  background: goldenrod;
}

.element:hover{
  background: gold;
}

.check{
  cursor: pointer;
}

#picture{
  margin-bottom: 5px;
  margin-top:-15px;
}

#selectedpicture{
  margin-left:5px;
}

#hidereveal{
  cursor: pointer;
}
@media screen and (max-width: 479px) {
  .hero .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}
#cards-section{
  padding-top:80px;
}
.paragraph-3{
  padding-bottom: 20px;
}
.segmented{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.segmented2{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 12px;
  margin-top: 12px;
}
.option{
  display: flex;
  flex-direction: row;
}
.optiontext{
  margin-left: 3px;
  margin-top: -4px;
}

#moreInfo {
  padding-bottom: 40px;
  height: 100px;
  max-width: 450px;
  overflow-y: auto;
  overflow-wrap: break-word;
}

.hollow-button.black{
  color: black;
  border-color: black;
  cursor: pointer;
}

.hollow-button.black:hover{
  color: goldenrod;
  border-color: goldenrod;
}

.brow{
  margin-top:8px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .heading-10.location{
    padding-top: 45px;
  }
}

</style>