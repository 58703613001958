<template>
  <div>
    <Header/>
    <router-view v-slot="{ Component }" style="min-height: 100vh">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  setup(){
    const beforeEnter = (el) =>{
      el.style.opacity = 0;
      el.style.transform = 'translateX(-100%)';
    }

    const enter = (el) =>{
      console.log("enter")
    }

    const afterEnter = (el) =>{
      console.log("enter")
      el.style.opacity = 1;

    }

    return {beforeEnter, enter, afterEnter};
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.fade-enter-from{
  transform: translateX(-100%);
}
.fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>