import { createRouter, createWebHistory } from "vue-router";
import axios from 'axios'

import About from "../views/About/About.vue";
import Events from "../views/Events/Events.vue";
import EventInfo from "../views/Events/EventInfo.vue";
import become from "../views/become-member.vue";
import login from "../views/util/login.vue";
import profile from "../views/profile.vue";
import global from "../views/global.vue";
import change from "../views/util/changePassword.vue";
import impressum from "../views/other/impressum.vue";

const routes = [
  {
    path: "/",
    redirect: '/about',
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
  },
  {
    path: "/events/:id",
    name: "Eventinfo",
    component: EventInfo,
  },
  {
    path: "/membership",
    name: "Mitglied werden",
    component: become,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: impressum,
  },
  {
    path: "/login",
    name: "Anmelden",
    component: login,
    beforeEnter: (to, from, next) =>{
      axios.get('/api2/users/auth', {withCredentials: true})
      .then((resp)=>{
          if (resp.data){
            next("/profile");
          } else {
            next();
          }
      })
    }
  },
  {
    path: "/change-password",
    name: "PWAndern",
    component: change,
  },
  {
    path: "/profile",
    name: "Profil",
    component: profile,
    beforeEnter: (to, from, next) =>{
      axios.get('/api2/users/auth', {withCredentials: true})
      .then((resp)=>{
          if (resp.data){
            next();
          } else {
            next("/login");
          }
      })
    }
  },
  {
    path: "/global",
    name: "Global",
    component: global,
    beforeEnter: (to, from, next) =>{
      axios.get('/api2/users/isAdmin', {withCredentials: true})
      .then((resp)=>{
          if (resp.data){
            next();
          } else {
            next("/login");
          }
      })
    }
  },
  { path: '/:pathMatch(.*)*', redirect: '/about', },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
