<template>
    <div>
        <div class="section one">
            <div class="w-container">
                <div class="section-title-group">
                    <h2 class="section-heading centered">Was dich erwartet</h2>
                </div>
                <div data-w-id="aed3bea6-6d8b-e3d4-2cc4-fc1734669458" style="opacity:1" class="white-box">
                    <p>Bevor du zu einem ordentlichen Mitglied der Nibelungia wirst, musst du die Fuxenzeit absolvieren. 
                        In dieser Zeit wird dir das Verbindungsleben nähergebracht und du kannst selbst Erfahrungen sammeln 
                        was es heißt, in einer Verbindung zu sein. Die Fuxenzeit endet mit der Burschung und der Ablegung des Burscheneids. 
                        Als Bursch darfst du mit einem Wahl und Antragsrecht aktiv den Verlauf der Verbindung mitgestalten.
                        Zudem werden dir viele andere Privilegien zuteil, wie unter anderem auch die freie 
                        Nutzung des Hauses inmitten Wiens.<br></p>
                </div>
                <h3 class="heading-2">ECKDATEN FUXENZEIT</h3>
                <div class="w-row">
                    <div class="w-col w-col-4">
                        <div data-w-id="13df17aa-a8ad-c873-3639-4f78372b2c64" style="opacity:1" class="white-box">
                            <h3 class="heading-3">veranstaltungen</h3>
                            <p>Obwohl du noch kein ordentliches Mitglied bist, darfst du natürlich trotzdem an fast allen Veranstaltungen teilnehmen. Fuchsen die sich einbringen sind hier besonders gern gesehen.<br></p>
                        </div>
                        <div data-w-id="eb3c9b9f-26bb-8e68-ec16-0b2d1519a23d" style="opacity:1" class="white-box">
                            <h3 class="heading-3">studienwoche</h3>
                            <p>Die Studienwoche ist eine Veranstaltung des ÖCVs. Dort wirst du eine Woche zu Seminaren und gutem Essen eingeladen. Es ist eine großartige Möglichkeit Mitglieder von anderen Verbindungen kennenzulernen.</p>
                        </div>
                    </div>
                    <div class="w-col w-col-4">
                        <div data-w-id="8e910f58-35cd-e834-1aa9-32abebb94c5d" style="opacity:1" class="white-box">
                            <h3>1-2 jahre</h3>
                            <p>Die Dauer hängt hauptsächlich davon ab, wann du deine Prüfungen absolvierst<br></p>
                        </div>
                        <div data-w-id="e1622df7-70ff-ea22-9b2c-0e32b605004f" style="opacity:1" class="white-box">
                            <h3>Freibier</h3>
                            <p>bitte verantwortungsvoll genießen<br></p>
                        </div>
                        <div data-w-id="03e7e141-8eb8-24e7-b59d-fe703b232eeb" style="opacity:1" class="white-box">
                            <h3>ungebunden</h3>
                            <p>Du kannst dich jederzeit entschließen uns zu verlassen, wenn du das Gefühl hast, dass es nicht das Richtige für dich ist. Dafür ist diese Zeit da!<br></p>
                        </div>
                    </div>
                    <div class="w-col w-col-4">
                        <div data-w-id="8e910f58-35cd-e834-1aa9-32abebb94c65" style="opacity:1" class="white-box">
                            <h3 class="heading-3">FUXENCONVENT</h3>
                            <p>Der Fuxenconvent dient hauptsächlich, um dich auf die Prüfungen und das Verbindungsleben vorzubereiten. Keine Sorge, es handelt sich dabei viel mehr um ein gemütliches Zusammensitzen unter Freunden, als um eine Vorlesung</p>
                        </div>
                        <div data-w-id="8e910f58-35cd-e834-1aa9-32abebb94c56" style="opacity:1" class="white-box">
                            <h3>Prüfungen</h3>
                            <p>Um Mitglied zu werden musst du zwei mündliche Prüfungen absolvieren. Die Fragen ergeben sich aus Verbindungswissen und aus den 4 Prinzipien<br></p>
                        </div>
                    </div>
                </div>
            </div>
            <img src=@/assets/images/Fuxenstall.jpg width="400"/>
        </div>
        <div class="section">
            <div class="w-container two">
                <div class="section-title-group">
                    <h2 class="section-heading centered member">Kann ich mitglied werden?</h2>
                </div>
                <h3 class="heading-2">ja, wenn</h3>
                
                <div class="w-row">
                    <transition
                        appear
                        @before-enter="beforeEnter" 
                        @enter="enter"
                    >
                        <div class="w-col w-col-4">
                            <div data-w-id="551c102a-dcec-09bb-14f8-262f1c27fe66" class="white-box"><img src="@/assets/images/iconmonstr-checkbox-checked-extended-thin.svg" loading="lazy" width="70" alt="">
                                <h3>Männlich</h3>
                                <p><br><br><br></p>
                            </div>
                        </div>
                    </transition>
                    <transition
                        appear
                        @before-enter="beforeEnter1" 
                        @enter="enter1"
                    >
                        <div class="w-col w-col-4">
                            <div data-w-id="551c102a-dcec-09bb-14f8-262f1c27fe72" style="opacity:1" class="white-box"><img src="@/assets/images/iconmonstr-checkbox-checked-extended-thin.svg" loading="lazy" width="70" alt="">
                                <h3>katholisch</h3>
                                <p>oder Mitglied einer mit Rom unierten Kirche<br><br></p>
                            </div>
                        </div>
                    </transition>
                    <transition
                        appear
                        @before-enter="beforeEnter2" 
                        @enter="enter2"
                    >
                        <div class="w-col w-col-4">
                            <div data-w-id="551c102a-dcec-09bb-14f8-262f1c27fe87" style="opacity:1" class="white-box"><img src="@/assets/images/iconmonstr-checkbox-checked-extended-thin.svg" loading="lazy" width="70" alt="">
                                <h3>Student</h3>
                                <p>auch Priester, angehender Student mit Reifeprüfung oder Absolvent eines Studiums</p>
                            </div>
                        </div>
                    </transition>
                    
                    
                    <p><strong>Nicht Männlich?</strong> Keine Sorge, es gibt viele <a href="https://www.lysi.de/damenverbindungen/oesterreich/nach-orten/">Damenverbindungen</a> und <a href="https://de.wikipedia.org/wiki/Gemischte_Studentenverbindung">gemischte Verbindungen</a> in Österreich.<br></p>
                    <p><strong>Nicht Katholisch?</strong> Wenn du evangelisch bist, könnest du es bei der <a href="http://www.a-s.at/">Austria Sagitta</a> versuchen.<br></p>
                    <p><strong>Nicht Student?</strong> Wenn du noch in die Mittelschule gehst, wirst du im <a href="https://www.mkv.at/">MKV</a> fündig. Dir stehen natürlich unsere Türen offen, wenn du zu Studieren beginnst.<br></p>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="container-8 w-container">
                <div class="section-title-group">
                    <h2 class="section-heading centered">Kontaktiere uns</h2>
                    <div class="section-subheading center">wir freuen uns von dir zu hören!</div>
                </div>
                <p><strong>Unsicher?</strong> Gib uns Beschied und komm einmal vorbei! Wir beantworten gerne all deine Fragen.<br></p>
                <div class="form-wrapper w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" class="form" v-bind:action='"https://formsubmit.co/" + "chc@nibelungia.at"' method="POST">
                        <input type="text" class="form-field w-input" maxlength="256" name="Name" data-name="Name" placeholder="Name..." id="Name" required="">
                        <input type="email" class="form-field w-input" maxlength="256" name="Email" data-name="Email" placeholder="Email..." id="Email" required="">
                        <textarea id="field-2" name="field-2" placeholder="Nachricht..." maxlength="5000" data-name="Field 2" class="form-field text-area w-input"></textarea>
                        <input type="submit" value="SENDEN" data-wait="Please wait..." class="button full-width w-button">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";
import axios from 'axios'

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'Events',
    data () {
        return {
            general: Object
        };
    },
    components: {
    },
    mounted () {
        window.scrollTo(0, 0);
        axios.get('/api2/general')
        .then((resp)=>{
            this.general = resp.data;
        })
    },
    methods:{
        handleSubmit(e){
            e.preventDefault();
            axios.post('/api2/users/login', {email: this.email, password: this.password} , {withCredentials: true})
            .then((resp)=>{
                console.log(resp.data);
                if (resp.data){
                    location.reload();
                }
            })
            .catch(err => {
                createToast('E-Mail oder Passwort ist inkorrekt', {
                    type: 'warning',
                    position: 'top-center',
                    showIcon: true,
                    timeout: 5000
                })
            });
        }
    },
    setup(){
        const beforeEnter = (el) =>{
            el.style.opacity = 0;
            el.style.transform = 'translateY(100px)'
        }

    const enter = (el, done) =>{
        gsap.to(el, {
            scrollTrigger: {
                trigger: "el",
                start: "47% 75%",
                toggleAction: "restart pause reverse pause"
            },
            opacity: 1,
            y: 0,
            duration: 1.5,
            onComplete: done
        })
    }

    const beforeEnter1 = (el) =>{
      el.style.opacity = 0;
      el.style.transform = 'translateY(100px)'
    }

    const enter1 = (el, done) =>{
      gsap.to(el, {
        scrollTrigger: {
            trigger: "el",
            start: "47% 75%",
            toggleAction: "restart pause reverse pause"
        },
        opacity: 1,
        y: 0,
        duration: 1.5,
        onComplete: done,
        delay: 0.4
      })
    }

    const beforeEnter2 = (el) =>{
      el.style.opacity = 0;
      el.style.transform = 'translateY(100px)'
    }

    const enter2 = (el, done) =>{
      gsap.to(el, {
        scrollTrigger: {
            trigger: "el",
            start: "47% 75%",
            toggleAction: "restart pause reverse pause"
        },
        opacity: 1,
        y: 0,
        duration: 1.5,
        onComplete: done,
        delay: 0.8
      })
    }

    return {beforeEnter, enter, beforeEnter1, enter1, beforeEnter2, enter2};
  }
}
</script>

<style scoped>
.section-heading.centered{
    padding-top:20px;
}

.section-heading.centered.member{
    padding-top:0px;
    margin-top:-100px;
}


@media screen and (max-width: 479px) {
  .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 991px) {
  .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}

</style>