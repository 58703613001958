<template>
    <div>
        <div class="w-container">
            <div class="div-block-3">
                <div class="w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" class="form-3" @submit="handleSubmit">
                        <h2>Passwort ändern</h2>
                        <input type="password" class="w-input" maxlength="256" name="oldPasswort" data-name="oldPasswort" placeholder="Altes Passwort" id="oldPasswort" required="" v-model="oldPwd">
                        <input type="password" class="w-input" maxlength="256" name="newPasswort1" data-name="newPasswort1" placeholder="Neues Passwort" id="newPasswort1" required="" v-model="newPwd1">
                        <input type="password" class="w-input" maxlength="256" name="newPasswort2" data-name="newPasswort2" placeholder="Neues Passwort bestätigen" id="newPasswort2" required="" v-model="newPwd2">
                        <input type="submit" value="WEITER" data-wait="Please wait..." class="submit-button-2 w-button">
                    </form>
                <div class="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
    name: 'Login',
    components: {
    },
    mounted () {
        window.scrollTo(0, 0)
    },
    data() {
        return {
            oldPwd: '',
            newPwd1: '',
            newPwd2: ''
        }
    },
    methods:{
        handleSubmit(e){
            e.preventDefault();
            if (this.newPwd1 == this.newPwd2){
                axios.patch('/api2/users/me/pwd', { oldPwd: this.oldPwd, newPwd: this.newPwd1 }, {withCredentials: true})
                .then((resp)=>{
                    if (resp.data === true){
                        createToast('Passwort wurde geändert', {
                            type: 'success',
                            position: 'top-center',
                            showIcon: true,
                            timeout: 5000
                        })
                    } else {
                        createToast(resp.data, {
                            type: 'warning',
                            position: 'top-center',
                            showIcon: true,
                            timeout: 5000
                        })
                    }
                });
            } else {
                createToast('"Neues Passwort" und "Neues Passwort bestätigen", stimmen nicht überein', {
                    type: 'warning',
                    position: 'top-center',
                    showIcon: true,
                    timeout: 5000
                })
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
    width: 100%;
}
.div-block-3{
    width: 400px;
    margin-left: 10px;
    margin-right: 10px;
}
.w-container{
    display: flex;
    justify-content:space-around;
}
</style>