<template>
<div>
  <div>
    <section id="cards-section" class="cards-section">
      <div class="centered-container w-container">
        <h2 class="heading-7">Kommende Veranstatungen</h2>
        <p class="paragraph-3" v-if="events.length > 0">Es sind viele Veranstaltungen geplant! Wir würden uns freuen, wenn du bei der Nächsten teilnehmen würdest.</p>
        <p class="paragraph-3" v-else>Leider sind gerade keine Veranstaltungen geplant. Schau später noch einmal vorbei</p>
        <div class="cards-grid-container">
          <transition-group 
            appear 
            @before-enter="beforeEnter" 
            @enter="enter"
          >
            <div v-for="(event,index) in events.slice(0,3)" :key="event.title" :data-index="index">
              <Event :event="event"/>
            </div>
          </transition-group>
          <transition-group 
            appear 
            @before-enter="beforeEnter" 
            @enter="enter"
            v-if="!showLess"
          >
            <div v-for="(event,index) in events.slice(3)" :key="event.title" :data-index="index">
              <Event :event="event"/>
            </div>
          </transition-group>
        </div>
      </div>
      <div v-if="events.length > 3">
        <div class="container-3 w-container" v-if="showLess">
          <a @click="showLess = false" class="button" id="hidereveal">MEHR ANZEIGEN</a>
        </div>
        <div class="container-3 w-container" v-else>
          <a @click="showLess = true" class="button" id="hidereveal">WENIGER ANZEIGEN</a>
        </div>
      </div>
      <div class="container-3 w-container" v-if="isAdmin">
        <a class="button" id="hidereveal" @click="showPop = true">weitere Veranstaltung hinzufügen</a>
      </div>
      <Popup v-if="showPop" v-bind:showPop="showPop">
        <h2>Neue Veranstaltung hinzufügen</h2>
        <form @submit="handleSubmit">
          <input type="text" class="w-input" maxlength="256" name="title" data-name="title" placeholder="Veranstaltungsname" id="title" required="" v-model="newEvent.title">
          <div class="segmented">
            <div class="option">
              <input type="radio" name="printSize" id="print-size-s" value="CC" v-model="newEvent.type">
              <label class="optiontext" id="CC">CC</label>
            </div>
            <div class="option">
              <input type="radio" name="printSize" id="print-size-m" value="BC" v-model="newEvent.type">
              <label class="optiontext" id="BC">BC</label>
            </div>
            <div class="option">
              <input type="radio" name="printSize" id="print-size-l" value="AC" v-model="newEvent.type">
              <label class="optiontext" id="AC">AC</label>
            </div>
            <div class="option">
              <input type="radio" name="printSize" id="print-size-l" value="FC" v-model="newEvent.type">
              <label class="optiontext" id="FC">FC</label>
            </div>
            <div class="option">
              <input type="radio" name="printSize" id="print-size-l" value="S" v-model="newEvent.type">
              <label class="optiontext" id="sonstiges">Sonstige</label>
            </div>
          </div>
          <input type="text" class="w-input" maxlength="256" name="date" data-name="date" placeholder="Datum (DD.MM.YYYY)" pattern="(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20|21)\d\d" id="date" v-model="newEvent.date">
          <input type="text" class="w-input" maxlength="256" name="time" data-name="time" placeholder="Uhrzeit (HH:MM)" pattern="(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]" id="time" v-model="newEvent.time">
          <input type="text" class="w-input" maxlength="256" name="location" data-name="location" placeholder="Ort" id="location" v-model="newEvent.location">
          <div class="segmented">
            <div class="option">
              <input type="radio" name="importance" id="hochoff" value="hochoffiziell" v-model="newEvent.importance">
              <label class="optiontext" id="CC">Hochoffiziell</label>
            </div>
            <div class="option">
              <input type="radio" name="importance" id="off" value="offiziell" v-model="newEvent.importance">
              <label class="optiontext" id="BC">Offiziell</label>
            </div>
            <div class="option">
              <input type="radio" name="importance" id="frei" value="frei" v-model="newEvent.importance">
              <label class="optiontext" id="AC">frei</label>
            </div>
          </div>
          <div class="segmented2">
            <div class="option">
              <input type="radio" name="signup" id="print-size-s" value="true" v-model="newEvent.signUp">
              <label class="optiontext" id="CC">Mit Anmeldung</label>
            </div>
            <div class="option">
              <input type="radio" name="signup" id="print-size-m" value="false" v-model="newEvent.signUp">
              <label class="optiontext" id="BC">Ohne Anmeldung</label>
            </div>
          </div>
          <img >
          <div id="picture">
            <img src="@/assets/images/iconmonstr-picture-thin.svg" width="30" height="30" loading="lazy" alt="" class="check" @click="showImgPop = true">
            <a href="#" id="selectedpicture" @click="showImgPop = true">{{ newEvent.img }}</a>
          </div>
          <textarea v-model="newEvent.note" placeholder="Anmerkungen" class="w-input" id="moreInfo"></textarea>
          <div class="brow">
            <input type="submit" value="Hinzufügen" data-wait="Please wait..." class="submit-button-2 w-button">
            <a class="hollow-button black" @click="showPop = false">Abbrechen</a>
          </div>
        </form>
      </Popup>
      <Popup v-if="showImgPop">
        <div class="image-list">
          <div class="element active" v-if='newEvent.img == "Wappen-NbWHD.jpg"'>
              <img src="@/assets/images/Wappen-NbWHD.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/Wappen-NbWHD.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "Wappen-NbWHD.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "document.jpg"'>
              <img src="@/assets/images/document.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/document.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "document.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "kneipe.jpg"'>
              <img src="@/assets/images/kneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/kneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "kneipe.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "skiwochenende.jpg"'>
              <img src="@/assets/images/skiwochenende.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/skiwochenende.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "skiwochenende.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "krambambuli.jpg"'>
              <img src="@/assets/images/krambambuli.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/krambambuli.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "krambambuli.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "messe.jpg"'>
              <img src="@/assets/images/messe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/messe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "messe.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "budenabend.jpg"'>
              <img src="@/assets/images/budenabend.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/budenabend.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "budenabend.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "ac.jpg"'>
              <img src="@/assets/images/ac.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/ac.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "ac.jpg"'>
          </div>
          
          <div class="element active" v-if='newEvent.img == "FC.jpg"'>
              <img src="@/assets/images/FC.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/FC.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "FC.jpg"'>
          </div>

          <div class="element active" v-if='newEvent.img == "trauerkneipe.jpg"'>
              <img src="@/assets/images/trauerkneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg">
          </div>
          <div class="element" v-else>
              <img src="@/assets/images/trauerkneipe.jpg" width="128" height="128" loading="lazy" alt="" class="previewimg" @click='newEvent.img = "trauerkneipe.jpg"'>
          </div>
        </div>
        <a class="hollow-button black" @click="showImgPop = false">Schliessen</a>
      </Popup>
    </section>
    <header id="hero" class="hero">
        <div class="flex-container-2 w-container">
        <div>
            <h1 class="heading-15">Semesterprogramm</h1>
            <p>Das Semesterprogramm ist ein Überblick über alle geplanten Veranstaltungen und ist im PDF-Format als Download erhältlich.</p>
            <a v-bind:href="'https://drive.google.com/file/d/19dGbxqV0eypCF0pex9VMvErjfKfIFbqB/view?usp=sharing'" class="button">Download</a>
        </div>
        <div class="hero-image-mask"><img src="@/assets/images/northfolk-pxNcBLSPSMc-unsplash.jpg" sizes="(max-width: 767px) 92vw, (max-width: 991px) 49vw, 481.13751220703125px" alt="" class="hero-image"></div>
        </div>
    </header>
  </div>
</div>
</template>

<script>
import Event from '@/components/Event.vue'
import Popup from '@/components/Popup.vue'
import gsap from 'gsap'
import axios from 'axios'

export default {
  name: 'Events',
  components: {
    Event,
    Popup
  },
  data () {
    return {
      events: [],
      showLess: true,
      isAdmin: false,
      showPop: false,
      showImgPop: false,
      newEvent: {
        title: "",
        date: "",
        type: "S",
        importance: "frei",
        signUp: false,
        location: "Breite Gasse 16",
        time: "",
        img:"Wappen-NbWHD.jpg",
        note: "",
      },
      general: Object
    };
  },
  mounted () {
    window.scrollTo(0, 0);
    axios.get('/api2/events')
    .then((resp)=>{
      this.events = sortByDate(resp.data);
    })
    axios.get('/api2/users/isAdmin', {withCredentials: true})
    .then((resp)=>{
      this.isAdmin = resp.data;
    })
    axios.get('/api2/general')
    .then((resp)=>{
      this.general = resp.data;
    })


    function compareDate(date){
      const today = new Date;
      date = date.split(".");

      if (date[2] < today.getFullYear()){
        return false;
      } else if (date[2] > today.getFullYear()) {
        return true;
      } else {
        if (date[1] < today.getMonth()+1){
          return false;
        } else if (date[1] > today.getMonth()+1) {
          return true;
        } else {
          if (date[0] < today.getDate()){
            return false;
          } else {
            return true;
          }
        }
      }
    }

    function sortByDate(array){
      let listOfValue = new Array;
      for (let i = 0; i < array.length; i++){
        let date = array[i].date;
        let dates = date.split(".");
        let val = parseInt(dates[0]) + parseInt(dates[1])*100 + parseInt(dates[2])*10000;
        listOfValue.push(val)
      }

      for (let i = 0; i < array.length; i++){
        let min = listOfValue[i];
        let index = i;
        for(let j = i; j < array.length; j++){
          if (listOfValue[j] < min){
            min = listOfValue[j];
            index = j;
          }
        }
        let temp = listOfValue[i];
        listOfValue[i] = min;
        listOfValue[index] = temp;

        temp = array[i];
        array[i] = array[index];
        array[index] = temp;
      }

      for (let i = array.length-1; i > 0; i--){
        if (!compareDate(array[i].date)){
          array = array.slice(i+1);
          break;
        }
      }

      return array;
    }
  },
  setup(){
    const beforeEnter = (el) =>{
      el.style.opacity = 0;
      el.style.transform = 'translateY(100px)'
    }

    const enter = (el, done) =>{
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 1,
        onComplete: done,
        delay: el.dataset.index * 0.2
      })
    }

    return {beforeEnter, enter};
  },
  methods:{
        handleSubmit(e){
            e.preventDefault();
            axios.post('/api2/events/', {title: this.newEvent.title, date: this.newEvent.date, type: this.newEvent.type, importance: this.newEvent.importance, signUp: this.newEvent.signUp, location: this.newEvent.location, time: this.newEvent.time, img: this.newEvent.img, note: this.newEvent.note, tags: this.newEvent.note.tags}, {withCredentials: true})
            .then((resp)=>{
                this.showPop = false;
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hollow-button.black.mt{
  margin-top: 15px;
}

.offi{
  margin: 0;
  color: black;
}
.hochoffiziell{
  text-align: center;
  background: gold;
  
}
.offiziell{
  text-align: center;
  background: rgb(54, 190, 0);
}

.heading-27.margintop{
  margin-top: 40px;
}

.center{
  display: flex;
  justify-content: center;
}

.image-list{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 600px;
}

.previewimg{
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.element{
  padding: 3px;
  border: solid 1px black;
  margin: 5px;
  transition: 200ms ease-in-out;
  background: gray;
}

.element.active{
  background: goldenrod;
}

.element:hover{
  background: gold;
}

.check{
  cursor: pointer;
}

#picture{
  margin-bottom: 5px;
  margin-top:-15px;
}

#selectedpicture{
  margin-left:5px;
}

#hidereveal{
  cursor: pointer;
}
@media screen and (max-width: 479px) {
  .hero .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}
#cards-section{
  padding-top:80px;
}
.paragraph-3{
  padding-bottom: 20px;
}
.segmented{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.segmented2{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 12px;
  margin-top: 12px;
}
.option{
  display: flex;
  flex-direction: row;
}
.optiontext{
  margin-left: 3px;
  margin-top: -4px;
}

#moreInfo {
  padding-bottom: 40px;
  height: 100px;
  max-width: 450px;
  overflow-y: auto;
  overflow-wrap: break-word;
}

.hollow-button.black{
  color: black;
  border-color: black;
  cursor: pointer;
}

.hollow-button.black:hover{
  color: goldenrod;
  border-color: goldenrod;
}

.brow{
  margin-top:8px;
  text-align: center;
}
</style>