<template>
    <div>
        <div class="section">
            <div class="w-container">
                <div class="section-title-group">
                    <h2 class="section-heading centered"> {{ profiledata.firstName }} {{ profiledata.lastName}} v/o {{ profiledata.coleurName }}</h2>
                </div>
                <div data-w-id="aed3bea6-6d8b-e3d4-2cc4-fc1734669458" class="white-box">
                  <div class="inline" v-if="editmode != 1">
                    <p><b>Vorname:</b> {{ profiledata.firstName }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 1">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="firstName" data-name="newFirstName" placeholder="Vorname" id="firstName" v-model="newFirstName"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 2">
                    <p><b>Nachname:</b> {{ profiledata.lastName }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 2">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="lastName" data-name="newLastName" placeholder="Nachname" id="lastName" v-model="newLastName"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 3">
                    <p><b>Coleurname:</b> {{ profiledata.coleurName }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 3">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="text" class="w-input" maxlength="256" name="colName" data-name="newColName" placeholder="Coleurname" id="colName" v-model="newColName"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                  <div class="inline" v-if="editmode != 4">
                    <p><b>Email:</b> {{ profiledata.email }}  </p>
                    <img src="../assets/images/edit-document.svg" width="24" loading="lazy" alt="" class="edit" v-on:click="editmode = 4">
                  </div>
                  <form v-else @submit="handleSubmit">
                    <div class="inline">
                      <p> <input type="email" class="w-input" maxlength="256" name="email" data-name="newEmail" placeholder="E-Mail" id="newEmail" v-model="newEmail"> </p>
                      <button type='submit' name='inputname' value='inputvalue' style='background:none;border:none;padding:0'><img src="../assets/images/iconmonstr-checkbox-checked-extended-thin.svg" width="30" height="30" loading="lazy" alt="" class="check"></button>
                    </div>
                  </form>

                </div>
                <a href="#" class="hollow-button" v-on:click="logout">Abmelden</a>
                <router-link to="/change-password"><a href="#" class="hollow-button changePwd" v-on:click="toggleme">Passwort ändern</a></router-link>
                <router-link to="/global"><a href="#" class="hollow-button changePwd">Allgemeine Informationen ändern</a></router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Profile',
  data () {
    return {
      active: Boolean,
      profiledata: Object,
      editmode: Number,
      newFirstName: String,
      newLastName: String,
      newColName: String,
      newEmail: String,
      isAdmin: false
    };
  },
  mounted () {
    window.scrollTo(0, 0);
    this.profiledata = {};
    this.newFirstName = "";
    this.newLastName = "";
    this.newColName = "";
    this.newEmail = "";
    axios.get('/api2/users/me', {withCredentials: true})
    .then((resp)=>{
      if (resp.data !== false){
        this.profiledata = resp.data;
      }
    });
    axios.get('/api2/users/isAdmin', {withCredentials: true})
    .then((resp)=>{
      this.isAdmin = resp.data;
    });
  },
  methods: {
    logout: function (event) {
        axios.get('/api2/users/logout', {withCredentials: true})
        .then((resp)=>{
            location.reload();
        });
    },
    handleSubmit(e){
      e.preventDefault();
      this.editmode=0;

      if (this.newFirstName != ""){
        axios.patch('/api2/users/me', { firstName: this.newFirstName }, {withCredentials: true})
        .then((resp)=>{
            this.profiledata.firstName = this.newFirstName;
            this.newFirstName = "";
        });
      }

      if (this.newLastName != ""){
        axios.patch('/api2/users/me', { lastName: this.newLastName }, {withCredentials: true})
        .then((resp)=>{
            this.profiledata.lastName = this.newLastName;
            this.newLastName = "";
        });
      }

      if (this.newColName != ""){
        axios.patch('/api2/users/me', { coleurName: this.newColName }, {withCredentials: true})
        .then((resp)=>{
            this.profiledata.coleurName = this.newColName;
            this.newColName = "";
        });
      }

      if (this.newEmail != ""){
        axios.patch('/api2/users/me', { email: this.newEmail }, {withCredentials: true})
        .then((resp)=>{
            this.profiledata.email = this.newEmail;
            this.newEmail = "";
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section{
  background: transparent;
}

.white-box{
  background: transparent;
  border-color: black;
}

.inline{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.edit{
  margin-left: 10px;
}

.check{
  margin-left: 10px;
  margin-bottom: 10px;
}

.edit:hover{
  cursor: pointer;
}

.check:hover{
  cursor: pointer;
}

.section-heading.centered{
    padding-top:20px;
}

.hollow-button{
    color: black;
    border-color: black;
}

@media screen and (max-width: 479px) {
  .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }

  .hollow-button.changePwd{
    margin-top: 15px;
  }
}
@media screen and (max-width: 991px) {
  .button {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}
</style>