<template>
  <div>
    
    <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" class="navigation-bar w-nav two" v-if="active">
      <div class="w-container">
        <ul style="list-style-type:none;">
          <li><router-link to="/about" class="navigation-link w-nav-link">Über Uns</router-link></li>
          
          <li><router-link to="/events" class="navigation-link w-nav-link">Veranstaltungen</router-link></li>

          <li><router-link to="/membership" class="navigation-link w-nav-link">Mitglied werden</router-link></li>

          <li>
            <router-link to="/login" class="navigation-link w-nav-link">Anmelden</router-link>
            <!-- <router-link to="/profile" class="navigation-link w-nav-link" v-on:click="toggleme" v-else>{{ profilename }}</router-link> -->
          </li>
        </ul>
      </div>
    </div>
    
    
    <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" class="navigation-bar w-nav">
      <div class="w-container">
        <nav role="navigation" class="navigation-menu w-nav-menu">

          <router-link to="/about" class="navigation-link w-nav-link">Über Uns</router-link>

          <router-link to="/events" class="navigation-link w-nav-link">Veranstaltungen</router-link>

          <!--<router-link to="/news" class="navigation-link w-nav-link">Aktuelles</router-link>-->

          <router-link to="/membership" class="navigation-link w-nav-link">Mitglied werden</router-link>

          <router-link to="/login" class="navigation-link w-nav-link" v-if='profilename == ""'>Anmelden</router-link>
          <router-link to="/profile" class="navigation-link w-nav-link" v-else>{{ profilename }}</router-link>

        </nav>
        <div class="hamburger-button w-nav-button">
          <button class="hamburger hamburger--spin" type="button" v-on:click="toggleme">
              <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
              </span>
          </button>
        </div>
        <router-link to="/about">
        <img src="@/assets/images/zirkel.png" loading="lazy" width="57" alt="" class="image-2"></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Header',
  methods: {
    toggleme: function (event) {
      var hamburger = document.querySelector(".hamburger");
      hamburger.classList.toggle("is-active");
      this.active = hamburger.classList.contains("is-active");
    }
  },
  data () {
    return {
      active: Boolean,
      profilename: String
    };
  },
  mounted () {
    this.profilename = "";
    this.active = false;
    axios.get('/api2/users/me', {withCredentials: true})
    .then((resp)=>{
      if (resp.data !== false){
        this.profilename = resp.data.coleurName;
      }
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a.router-link-active{
  color:goldenrod;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after  {
    width: 30px;
    height: 4px;
}

 .hamburger-box {
    margin: 0;
    padding: 0;
}

.hamburger{
    margin: 0;
    padding: 0;
}

.hamburger.is-active{
    margin: 0;
    padding: 0;
}

.navigation-bar.w-nav.two{
  position: fixed 0 0;
  padding-top: 60px;
  background: rgb(41, 40, 40);
  height: auto;
}
.navigation-bar.w-nav.two ul li{
  margin: 0;
  padding: 0;
}

.navigation-bar.w-nav.two{
  color: rgb(255, 255, 255);
}
.navigation-bar.w-nav.two .navigation-link.w-nav-link:hover{
  color: gold;
}
.navigation-bar.w-nav.two .navigation-link.w-nav-linka.router-link-active{
  color:goldenrod;
}
.slidein-enter-active,
.slidein-leave-active {
  transition: all 0.3s ease-in-out;
}

.slidein-enter-from{
  transform: translateY(-100%);
}
.slidein-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

@media screen and (min-width: 991px) {
  .navigation-bar.w-nav.two{
    display: none;
  } 
}
</style>
