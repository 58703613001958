<template>
  <div>
    
    <nav class="hero-section centered home">
        <transition 
          appear 
          @before-enter="beforeEnter" 
          @enter="enter"
        >

          <div data-w-id="e464d218-f801-55d1-1f50-7da00b5bfb8f" class="container w-container">
            <h1 data-ix="fade-in-bottom-page-loads" class="hero-heading">K.Ö.St.V. <br>NIBELUNGIA WIEN</h1>
            <div data-ix="fade-in-bottom-page-loads" class="hero-subheading">vivat, crescat, floreat</div>
            <div data-ix="fade-in-bottom-page-loads">
              <router-link to="/login"><a href="#" class="button">ANMELDEN</a></router-link>
              <router-link to="/membership"><a href="#" class="hollow-button">Mitglied werden</a></router-link>
            </div>
          </div>
        </transition>
    </nav>
    
    <div class="section">
      <div class="container-2 w-container">
        <div class="section-title-group">
          <h2 class="section-heading centered">Über uns</h2>
        </div>
      </div>
      <div class="w-container">
        <div class="white-box">
          <div class="columns w-row">
            <div class="column w-col w-col-4"><img src="../../assets/images/Wappen-NbWHD.jpg" loading="lazy" width="377" sizes="(max-width: 767px) 79vw, (max-width: 991px) 29vw, 289.32501220703125px" srcset="../../assets/images/Wappen-NbWHD-p-500.jpeg 500w, ../../assets/images/Wappen-NbWHD.jpg 768w" alt=""></div>
            <div class="w-col w-col-8">
              <p>Die <strong>KÖStV Nibelungia Wien</strong> ist eine katholische, österreichische, farbentragende Studentenverbindung. Eine Studentenverbindung ist aber noch mehr – eine Gesinnungsgemeinschaft, beruhend auf unseren vier Prinzipien religio, patria, amicitia und scientia, in der Jung und Alt zusammen wirken und auch zusammen feiern.Der Vorstand einer Studentenverbindung wird von den Aktiven, das sind die Studenten, gestellt. Bei uns bietet sich also die Möglichkeit, Verantwortung zu übernehmen und auch die so oft gepriesenen “soft skills”, also Fähigkeiten wir etwa Teamarbeit, Organisation, Verhandlungsgeschick etc. zu erlernen.<br>
              Nibelungia ist Mitglied im Österreichischen Cartellverband (ÖCV), dem größten Akademikerbund Österreichs. Im ÖCV sind 50 Verbindungen in allen Universitätsstädten Österreichs organisiert. Es ist eine liebe Tradition auch andere Verbindungen zu besuchen. Dadurch ergeben sich Freundschaften, die sonst wohl allein wegen der räumlichen Distanz nicht möglich gewesen wären.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section accent">
      <div class="w-container">
        <div class="section-title-group">
          <h2 class="section-heading centered white">4 Prinzipien</h2>
          <div class="section-subheading center off-white">Die Grundprinzipien aller mitglieder des öcv</div>
        </div>
        <div class="w-row">
          <div class="w-col w-col-6">
            <div class="white-box transparent"><img src="@/assets/images/religio_1.jpg" sizes="(max-width: 767px) 85vw, (max-width: 991px) 43vw, 430px" srcset="@/assets/images/religio_1-p-500.jpeg 500w, @/assets/images/religio_1-p-800.jpeg 800w, @/assets/images/religio_1-p-1080.jpeg 1080w, @/assets/images/religio_1.jpg 1200w" alt="" class="fullwidth-image">
              <h3 class="white-text">Religio</h3>
              <p class="white-text">Wir bekennen uns zum katholischen Glauben, zu einem Leben nach christlichen Grundsätzen im privaten und öffentlichen Bereich und zu einer ökumenischen Geisteshaltung.</p>
            </div>
          </div>
          <div class="w-col w-col-6">
            <div class="white-box transparent"><img src="@/assets/images/scientia.jpg" sizes="(max-width: 767px) 85vw, (max-width: 991px) 43vw, 430px" srcset="@/assets/images/scientia-p-500.jpeg 500w, @/assets/images/scientia-p-800.jpeg 800w, @/assets/images/scientia.jpg 1053w" alt="" class="fullwidth-image">
              <h3 class="white-text">Scientia</h3>
              <p class="white-text">Wir bekennen uns zur akademischen Ausbildung und zur lebenslangen Weiterbildung. Nach unserer Überzeugung verpflichtet uns dies zu einer erhöhten Verantwortung in der Gesellschaft.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-container">
        <div class="w-row">
          <div class="w-col w-col-6">
            <div class="white-box transparent"><img src="@/assets/images/amicitia.jpg" sizes="(max-width: 767px) 85vw, (max-width: 991px) 43vw, 430px" srcset="@/assets/images/amicitia-p-500.jpeg 500w, @/assets/images/amicitia-p-800.jpeg 800w, @/assets/images/amicitia-p-1080.jpeg 1080w, @/assets/images/amicitia.jpg 1200w" alt="" class="fullwidth-image">
              <h3 class="white-text">Amicitia</h3>
              <p class="white-text">Wir bekennen uns zur Idee der Brüderlichkeit und der redlichen Hilfsbereitschaft. Unter allen Mitgliedern unserer Verbindung und des Verbandes wird das Du-Wort verwendet.</p>
            </div>
          </div>
          <div class="w-col w-col-6">
            <div class="white-box transparent"><img src="@/assets/images/patria_1.jpg" sizes="(max-width: 767px) 85vw, (max-width: 991px) 43vw, 430px" srcset="@/assets/images/patria_1-p-500.jpeg 500w, @/assets/images/patria_1-p-1080.jpeg 1080w, ../../assets/images/patria_1.jpg 1200w" alt="" class="fullwidth-image">
              <h3 class="white-text">patria</h3>
              <p class="white-text">Wir bekennen uns zum souveränen Staat Österreich und seinen aus der Geschichte und Lage gewachsenen besonderen Aufgaben.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'About',
  components: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  setup(){
    const beforeEnter = (el) =>{
      el.style.opacity = 0;
      el.style.transform = 'translateY(100px)'
    }

    const enter = (el, done) =>{
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 1,
        onComplete: done
      })
    }

    return {beforeEnter, enter};
  },
}
</script>

<style scoped>
</style>