import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '@/assets/css/hamburgers.css'
import '@/assets/css/normalize.css'
import '@/assets/css/webflow.css'
import '@/assets/css/main.css'
import '@/assets/css/fonts.css'

createApp(App).use(store).use(router).mount("#app");
