<template>
    <div>
        <div class="section">
            <h2 class="section-heading centered">Impressum</h2>
            <div class="w-container">
            
                <p>Sie befinden sich auf der Homepage der Katholisch-Österreichischen Studentenverbindung “Nibelungia”
                     in Wien (KÖStV Nibelungia).</p> 
                <p> Die <b>KÖStV Nibelungia</b> in Wien ist eine katholisch-österreichische Studentenverbindung.
                 Ihre 230 Mitglieder leben ihren katholischen Glauben und bekennen sich zum souveränen Staat Österreich und zu Europa. 
                 Die akademische Ausbildung bildet die Basis für aktive und kritische Mitarbeit in Staat und Gesellschaft.
                  Das weiß-gold-grüne Band symbolisiert die lebenslange Freundschaft, die unsere Verbindung zusammenhält. Gemeinsam mit 49 anderen katholischen Verbindungen bildet die Nibelungia den Österreichischen Cartellverband (ÖCV).</p>
                
                <h3>Kontaktdaten</h3>
                <p>KÖStV Nibelungia in Wien<br>Breite Gasse 16, Pf. 3<br>1070 Wien</p>
                <p>Tel/Fax: +43 1 522 35 65<br>
                Mail: chc@nibelungia.at</p>

                <p>Erklärungen gem. §5 ECG</p>

                <p>Domainbetreiber: KÖStV Nibelungia in Wien<br>
                Postanschrift: 1071 Wien, Breite Gasse 16, Pf. 3<br>
                Rechtsform: Verein – ZVR: 573440022<br>
                Aufsichtsbehörde: Bundespolizeidirektion Wien</p>

                <h3>Bankverbindungen</h3>
                <p><b>Aktivitas</b></p>
                <p>Raiffeisenlandesbank NÖ/Wien<br>
                Kto-Nr.: 11.345.816<br>
                BLZ 32.000<br>
                IBAN: AT36 3200 0000 1134 5816<br>
                BIC: RLNWATWW</p>

                <p><b>Altherrenschaft und Budenbeschaffung</b></p>
                <p>Raiffeisenlandesbank NÖ/Wien<br>
                Kto-Nr.: 2.007.227<br>
                BLZ 32.000<br>
                IBAN: AT42 3200 0000 0200 72 27<br>
                BIC: RLNWATWW</p>

                <h3>Erklärung zum Haftungsausschluss und der Geltung des österreichischen Urheberrechts.</h3>
                <p>Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert und implementiert. Eine Haftung für die Richtigkeit, Vollständigkeit und Aktualität dieser Webseiten kann aber nicht übernommen werden. Wir übernehmen insbesondere keinerlei Haftung für eventuelle Schäden oder Konsequenzen, die durch die direkte oder indirekte Nutzung der angebotenen Inhalte entstehen.</p>
                <p>Die KÖStV Nibelungia in Wien als Inhaltsanbieter ist für die eigenen Inhalte, die sie zur Nutzung bereithält, nach den allgemeinen Gesetzen verantwortlich. Von diesen eigenen Inhalten sind Querverweise (“externe Links”) auf die von anderen Anbietern bereitgehaltene Inhalte zu unterscheiden. Diese fremden Inhalte stammen weder von der KÖStV Nibelungia in Wien, noch hat sie die Möglichkeit, den Inhalt von Seiten Dritter zu beeinflussen. Die Inhalte fremder Seiten, auf die sie hinweist, spiegeln nicht ihre Meinung wider, sondern dienen lediglich der Information und der Darstellung von Zusammenhängen. Sie haftet nicht für fremde Inhalte jedweder Art – insbesondere Wort, Bild und Ton -, auf die lediglich im oben genannten Sinne hingewiesen wird. Die Verantwortlichkeit liegt alleine bei dem Anbieter der Inhalte.</p>
                <p>Im Allgemeinen gelten für die Seiten der KÖStV Nibelungia in Wien die Bestimmungen des österreichischen Urheberrechts.</p>
                
            </div>
        </div>
    </div>
    
</template>

<script>

export default {
  name: 'Impressum',
  components: {
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-heading.centered{
    padding-top:20px;
}

.section{
    text-align: left;
}
</style>